#scrollContent::-webkit-scrollbar {
  width: 6px;
}

/* Track */
#scrollContent::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
#scrollContent::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

/* Handle on hover */
#scrollContent::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.csv-reader-input {
  display: none;
}
